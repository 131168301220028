.moving-text {
    display: inline-block;
    white-space: nowrap;
    animation: scroll-text 10s linear infinite;
}

@keyframes scroll-text {
    0% {
        transform: translateX(250%);
    }
    100% {
        transform: translateX(-100%);
    }
}
